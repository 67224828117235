<template>
  <div class="entry">
    <div class="pcEntry" v-if="isPC">
      <div class='title-wrap'>
        <h1>教师测评</h1>
      </div>
      <div class="loginBox">
        <!--        <div class="left">-->
        <!--          <img class="logo" src="../assets/image/baicheng/logo.png" alt="" />-->
        <!--          <p>百城计划课题合作</p>-->
        <!--          <p style="margin-top:20px;">教师测评</p>-->
        <!--        </div>-->
        <div class="right">
          <el-form
              :model="loginForm"
              :rules="rules"
              ref="loginForm"
              class="loginForm"
          >
            <h1>教师测量登录</h1>
            <el-form-item prop="account">
              <el-input
                  prefix-icon="el-icon-user"
                  v-model="loginForm.account"
                  maxlength="20"
                  placeholder="请输入帐号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-form-item prop="passWord">
                <el-input
                    type="passWord"
                    prefix-icon="el-icon-circle-check"
                    v-model="loginForm.passWord"
                    show-password
                    autocomplete="off"
                    maxlength="20"
                    placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-button
                  class="loginBtn"
                  type="primary"
                  @click="submitForm('loginForm')"
              >登录
              </el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="mobileEntry" :style="{ height: bodyHeight + 'px' }" v-else>
      <div class="shadow">
<!--        <img class="logo" src="../assets/image/baicheng/logo.png" alt=""/>-->
        <p class="title">积极心理健康测试教师测评</p>
<!--        <p class="title" style="margin-top:1rem;">教师测评</p>-->
        <div class="login">
          <el-form
              :model="loginForm"
              :rules="rules"
              ref="loginForm"
              class="loginForm"
          >
            <h1>教师测量登录</h1>
            <el-form-item prop="account">
              <el-input
                  @focus="cancelInput"
                  prefix-icon="el-icon-user"
                  v-model="loginForm.account"
                  maxlength="20"
                  placeholder="请输入帐号"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-form-item prop="passWord">
                <el-input
                    @focus="cancelInput"
                    type="passWord"
                    prefix-icon="el-icon-circle-check"
                    v-model="loginForm.passWord"
                    show-password
                    autocomplete="off"
                    maxlength="20"
                    placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-button
                  class="loginBtn"
                  type="primary"
                  @click="submitForm('loginForm')"
              >登录
              </el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import md5 from 'js-md5';
import {userLogin, getNextQues} from '@/api/index';

export default {
  data() {
    return {
      bodyHeight: '',
      isPC: true,
      loading: false, //加载状态
      count: 0, //预加载进度
      percent: '', //预加载进度百分比
      loginForm: {
        account: '',
        passWord: '',
      },
      rules: {
        account: [
          {
            required: true,
            message: '请输入帐号',
            trigger: 'blur',
          },
        ],
        passWord: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
      status: true,
      timer: null,
    };
  },
  methods: {
    cancelInput() {
      // document.querySelector('body').scrollTop = 100;
      setTimeout(() => {
        document.querySelector('body').scrollIntoView(false);
      }, 300)
    },
    //登录
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let query = {
            account: this.loginForm.account,
            passWord: md5(this.loginForm.passWord),
          };
          userLogin(query)
              .then((res) => {
                if (res.code === 0) {
                  sessionStorage.setItem('fmTeaUserId', res.data.fmTeaUserId);
                  sessionStorage.setItem('account', res.data.account);
                  sessionStorage.setItem('name', res.data.name);
                  sessionStorage.setItem('evalName', res.data.evalName);
                  sessionStorage.setItem('token', res.data.token);
                  if (res.data.isFinished === 1) {
                    sessionStorage.setItem('isFinished', 1);
                    if (this.isPC) {
                      this.$router.replace({
                        path: '/finish'
                      });
                    } else {
                      this.$router.replace({
                        path: '/mFinish'
                      });
                    }
                  } else {
                    sessionStorage.setItem('isFinished', 0);
                    this.getEvalProgress();
                  }
                  if (this.isPC) {
                    this.$message({
                      message: '登录成功',
                      type: 'success',
                      duration: 1000,
                    });
                  }
                } else {
                  this.$message({
                    message: res.message,
                    type: 'error',
                    duration: 1000,
                  });
                }
              }).catch((error) => {
            console.log(error);
          });
        } else {
          return false;
        }
      });
    },
    //获取测评进度
    getEvalProgress() {
      let query = {fmTeaUserId: this.$util.getStorage('fmTeaUserId')};
      getNextQues(query)
          .then((res) => {
            if (res.data.isFinished === 1) {
              sessionStorage.setItem('isFinished', 1);
              if (this.isPC) {
                this.$router.replace({
                  path: '/finish'
                });
              } else {
                this.$router.replace({
                  path: '/mFinish'
                });
              }
            } else {
              if (res.data.quesNum == '0-1') {
                if (this.isPC) {
                  this.$router.replace({
                    path: '/ques'
                  });
                } else {
                  this.$router.replace({
                    path: '/mQues'
                  });
                }
              }
              if (res.data.quesNum == '0-2') {
                if (this.isPC) {
                  this.$router.replace({
                    path: '/evaluation'
                  });
                } else {
                  this.$router.replace({
                    path: '/mEvaluation'
                  });
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  created() {
    if (this.$util.isPC()) {
      this.isPC = true;
    } else {
      // document.title = '百城计划课题合作教师测评';
      this.isPC = false;
    }
  },
  mounted() {
    this.bodyHeight = document.documentElement.clientHeight;
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .pcEntry {
    //text-align: center;
    width: 100%;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('../assets/image/baicheng/tbg-main.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .title-wrap {
      padding-top: 150px;
      padding-left: 250px;

      h1 {
        color: white;
        text-align: left;
      }
    }

    .loginBox {
      margin-top: 50px;
      justify-items: start;
      width: 1000px;
      height: 450px;
      display: flex;
      padding-left: 200px;
      //background: rgba(48, 70, 93, 0.38);
      position: relative;
      //margin: 0 auto;
      //top: calc((100% - 450px) / 2);
      .right {
        width: 380px;
        padding: 50px;
        border-radius: 30px;
        display: inline-block;
        background: #ffffff;

        h1 {
          font-size: 24px;
          color: #30465D;
          margin-bottom: 50px;
        }

        .loginBtn {
          width: 100%;
          margin-top: 50px;
        }
      }
    }

    ::v-deep .el-form-item__label {
      width: 100%;
      display: block;
      text-align: left;
      color: #289257;
      font-size: 14px;
      line-height: 14px;
      padding: 13px 0 8px 0;
      font-family: 'SYHeiNormal';
    }

    ::v-deep .el-input__inner {
      background: #f2f2f2;
    }

    ::v-deep .el-form-item {
      margin-bottom: 30px;
    }

    ::v-deep
    .el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label:before {
      display: none;
    }
  }
}

@media screen and (min-width: 1900px) {
  .pcEntry {
    text-align: center;
    width: 100%;
    min-width: 1300px;
    height: 100vh;
    background-image: url('../assets/image/baicheng/tbg-main.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .loginBox {
      width: 1300px;
      height: 585px;
      display: flex;
      background: rgba(48, 70, 93, 0.38);
      position: relative;
      margin: 0 auto;
      top: calc((100% - 585px) / 2);

      .left {
        width: 806px;
        text-align: left;
        display: inline-block;

        .logo {
          width: 468px;
          height: 175px;
          margin: 130px 0 26px 0;
        }

        p {
          color: #ffffff;
          font-size: 36px;
          font-weight: 550;
          display: block;
          text-indent: 39px;
        }
      }

      .right {
        width: 494px;
        padding: 65px;
        display: inline-block;
        background: #ffffff;

        h1 {
          font-size: 38px;
          color: #689a6a;
          margin-bottom: 65px;
        }

        .loginBtn {
          width: 100%;
          height: 52px;
          font-size: 18px;
          margin-top: 65px;
        }
      }
    }

    ::v-deep .el-form-item__label {
      width: 100%;
      display: block;
      text-align: left;
      color: #289257;
      font-size: 18px;
      line-height: 18px;
      padding: 17px 0 10px 0;
      font-family: 'SYHeiNormal';
    }

    ::v-deep .el-input__inner {
      background: #f2f2f2;
      height: 52px;
      font-size: 18px;
    }

    ::v-deep .el-input__icon {
      font-size: 18px;
    }

    ::v-deep .el-form-item {
      margin-bottom: 39px;
      font-size: 18px;
    }

    ::v-deep
    .el-form-item.is-required:not(.is-no-asterisk)
    > .el-form-item__label:before {
      display: none;
    }
  }
}

.mobileEntry {
  width: 100vw;
  height: 100vh;
  background: url('../assets/image/baicheng/m-bg-main.jpg') no-repeat bottom;
  background-size: 100% 100%;
  position: relative;

  .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);

    .logo {
      width: 12rem;
      margin: 3rem 0 0 1rem;
    }

    .title {
      color: #ffffff;
      font-size: 2rem;
      font-weight: 550;
      margin: 1rem 0 0 2rem;
    }

    .login {
      margin-top: 3rem;
      padding: 3rem;
      text-align: center;

      h1 {
        color: #ffffff;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        text-align: left;
      }

      .loginBtn {
        width: 12rem;
        margin-top: 3rem;
        border-radius: 1rem;
      }

      ::v-deep .el-input__inner {
        border-radius: 2rem;
      }
    }
  }
}
</style>
